import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import { arraySorter, returnTitleSubHeadingsAndImages } from '../utilities/helperFunctions'
import Routes from '../utilities/Routes'
import BreadCrumbComponent from '../components/BreadCrumbComponent'
import SectionTitleComponent from "../components/SectionTitleComponent"
import CaseStudyCards from '../components/CaseStudyCards'
import DescriptionWithImageComponent from '../components/DescriptionWithImageComponent'
import TimeLineComponent from '../components/TimelineComponent'
import generalCss from '../styles/generalCss'
import { contactFormId } from '../utilities/Configurations'

export default function SolutionDetail(props) {
    const data = props.pageContext.data
    const heroData = returnTitleSubHeadingsAndImages(data)
    const processList = !!data.processSequence ? arraySorter({ array: data.processSequence, isNumberValue: true, checkKey: 'index' }) : []
    const featuredCaseStudies = !!data.featuredCaseStudies ? data.featuredCaseStudies : []
    const nonFeaturedCaseStudies = !!data.nonFeaturedCaseStudies ? data.nonFeaturedCaseStudies : []
    const breadCrumbs = [
        { id: 'home', label: "Home", route: Routes.home },
        { id: "solutions", label: "Solutions", route: Routes.solutions },
        { id: "solution-detail", label: heroData.title }
    ]
    const SEOData = !!data.Seo ? data.Seo : {}
    return (
        <div>
            <Layout pageSeo={SEOData}>
                <Hero
                    title={heroData.title}
                    subHeadings={[{ id: 'sub-title', value: heroData.subTitle }]}
                    coverImageMobile={heroData.coverImage}
                    coverImageWeb={heroData.coverImage}
                    buttonTitle='Get Started'
                    buttonHref={`${Routes.solutions}/${data.slug}/#${contactFormId}`}
                />
                <BreadCrumbComponent crumbsList={breadCrumbs} />
                <div className='mainContainer'>
                    {
                        !!data.details &&
                        <DescriptionWithImageComponent
                            title=''
                            showButton={true}
                            buttonTitle="Enquire"
                            description={!!data.details ? data.details : ''}
                            sectionId="description"
                            isRichText={true}
                            buttonHref={`${Routes.solutions}/${data.slug}/#${contactFormId}`}
                        />
                    }
                </div>
                {
                    processList.length !== 0 &&
                    <SectionTitleComponent
                        sectionId="process"
                        title="Our" coloredTitle="Process"
                        backgroundColor={featuredCaseStudies.length === 0 ? 'white' : generalCss.sectionBlueBg}
                    >
                        <TimeLineComponent processList={processList} />
                    </SectionTitleComponent>
                }
                {
                    featuredCaseStudies.length !== 0 &&
                    <SectionTitleComponent
                        sectionId='recent-case-studies'
                        title='Recent'
                        coloredTitle="Case Studies"
                    >
                        <CaseStudyCards
                            cardList={featuredCaseStudies}
                            featured={true}
                        />
                    </SectionTitleComponent>
                }
                {
                    nonFeaturedCaseStudies.length !== 0 &&
                    <SectionTitleComponent
                        sectionId='more-case-studies'
                        title='More'
                        backgroundColor={generalCss.sectionBlueBg}
                        coloredTitle="Case Studies"
                    // subTitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit,"
                    >
                        <CaseStudyCards
                            cardList={nonFeaturedCaseStudies.slice(0, 3)}
                            showViewAllButton={nonFeaturedCaseStudies.length > 3}
                            featured={false}
                        />
                    </SectionTitleComponent>
                }
            </Layout >
        </div >
    )
}
