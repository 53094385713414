import React from 'react'
import { Typography, Grid, makeStyles, Hidden } from '@material-ui/core'
import ImageComponent from './GeneralComponents/ImageComponent'
import PrimaryButton from './GeneralComponents/Buttons/PrimaryButton'
import RichTextComponent from './RichTextComponent'

const useStyles = makeStyles(theme => ({
    root: {
        padding: '20px',
        [theme.breakpoints.up('sm')]: {
            padding: "60px 2rem"
        }

    },
    imageStyles: {
        width: "100%",
        height: '100%',
        objectFit: 'cover',
        borderRadius: "1.5rem"
    }
}))

function DescriptionWithImage({
    description = '', isRichText = true,
    showButton = false, buttonTitle = '', buttonProps = {}, buttonHref = '',
    title = '', titleVariant = 'h2',
    thumbnailImage = { url: "", alternativeText: "" },
    flexDirection = 'row'
}) {
    const classes = useStyles()
    return <Grid container alignItems='stretch' direction={flexDirection} >
        {
            !!thumbnailImage.url &&
            <Grid item xs={12} sm={6}>
                <ImageComponent
                    src={thumbnailImage.url}
                    alt={thumbnailImage.alternativeText}
                    className={classes.imageStyles}
                />
            </Grid>
        }

        <Grid item xs={12} sm={!!thumbnailImage.url ? 6 : 12}>
            <Grid container spacing={4}>
                {
                    !!title &&
                    <Grid item xs={12}>
                        <Typography variant={titleVariant} color='textSecondary'>{title}</Typography>
                    </Grid>
                }
                <Grid item xs={12}>
                    {
                        isRichText ?
                            <RichTextComponent text={description} bannerHref={buttonHref} />
                            :
                            <Typography variant='body1' color='textPrimary'>{description}</Typography>
                    }
                </Grid>
                {
                    showButton &&
                    <Grid item xs={8} sm={4}>
                        <PrimaryButton href={buttonHref} {...buttonProps}>
                            {buttonTitle}
                        </PrimaryButton>
                    </Grid>
                }
            </Grid>
        </Grid>
    </Grid>
}

function DescriptionWithImageComponent({
    flexDirection = 'row', sectionId, ...props
}) {
    const classes = useStyles()
    return (
        <section id={sectionId}>
            <div className={classes.root}>
                <Hidden smUp implementation='css'>
                    <DescriptionWithImage {...props} flexDirection={'row'} />
                </Hidden>
                <Hidden xsDown implementation='css'>
                    <DescriptionWithImage {...props} flexDirection={flexDirection} />
                </Hidden>
            </div>
        </section>
    )
}

export default DescriptionWithImageComponent